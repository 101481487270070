import { useEffect } from 'react'

import { getAPIUrl } from '../config';
import useDomainInfo from './use-domain';

export const useGetFavicon = () => {
  const { domainData } = useDomainInfo();

  useEffect(() => {
    if (domainData) {
      document.title = `${domainData?.internal_brand?.company_name}`;

      const fav = document.querySelectorAll('.custom_fav');
      fav.forEach((currentFav) => {
        currentFav.setAttribute('href', `${domainData?.internal_brand?.dashboard_favicon ?
          domainData?.internal_brand?.trader_favicon?.includes('http') ? domainData?.internal_brand?.dashboard_favicon : `${getAPIUrl()}/${domainData?.internal_brand?.dashboard_favicon}` :
          ''}`);
      });
    }
  }, [domainData]);
}

